// Line Chart app details and configurations

export default {
  app_info: {
    app_name: "mkp-dev-recently-viewed",
    app_id: "cb42d177-ec38-4067-8224-d4107d4bb56c",
    app_title: "Recently Viewed Products",
    app_icon:
      "https://dev-reviews-mkp.nyc3.cdn.digitaloceanspaces.com/recently-viewed-products-js-snippet/Logo/Logo.png",
  },

  main_links: {
    faq_url:
      "https://help.marketpushapps.com/en/articles/9944402-how-to-use-recently-viewed-products",
    review_url:
      "https://www.wix.com/app-market/add-review/cb42d177-ec38-4067-8224-d4107d4bb56c",
  },

  storage: {
    collection_name: "mkp-dev-recently-viewed",
  },

  tutorial_main: {
    show: true,
    title: "📘 Getting Started Guide",
    description:
      "The Recently Viewed Products Widget helps users easily revisit products they've explored on your site. It automatically tracks and displays items based on user interactions, providing a seamless shopping experience. Customize the layout, display settings, and product details to match your website's design. Follow the guide to set up and optimize this engaging and user-friendly widget.",
    url: "https://help.marketpushapps.com/en/articles/9944402-how-to-use-recently-viewed-products",
  },

  tutorial_articles: {
    show: false,
    articles_collections: [
      {
        collection_name: "How to Recently Viewed Products",
        articles: [
          {
            title: "1. How to add Area Chart?",
            url: "https://help.marketpushapps.com/en/articles/9414693-how-to-use-area-chart#:~:text=1.%20How%20to%20Add%20Area%20Chart",
          },
          {
            title: "2. How To Represent Your data in Area Chart?",
            url: "https://help.marketpushapps.com/en/articles/9414693-how-to-use-area-chart#:~:text=2.%20How%20To%20Represent%20Your%20data.",
          },
          {
            title: "3. How To Change the Style of Your Chart?",
            url: "https://help.marketpushapps.com/en/articles/9414693-how-to-use-area-chart#:~:text=3.%20How%20To%20Change%20the%20Style%20of%20Your%20Chart.",
          },
          {
            title: "4. How do I get additional support?",
            url: "https://help.marketpushapps.com/en/articles/9414693-how-to-use-area-chart#:~:text=How%20do%20I%20get%20additional%20support%3F",
          },
          {
            title: "5. Add a Review for the app",
            url: "https://help.marketpushapps.com/en/articles/9414693-how-to-use-area-chart#:~:text=Add%20a%20Review%20for%20the%20app",
          },
        ],
      },
      // {
      //   collection_name: "Help Articles",
      //   articles: [
      //     {
      //       title: "How to add colors without a color picker?",
      //       url: "https://help.marketpushapps.com/en/articles/8900488-how-to-use-image-hotspots#h_e750f2ba12",
      //     },
      //     {
      //       title: "How to upload my own image?",
      //       url: "https://help.marketpushapps.com/en/articles/8900488-how-to-use-image-hotspots#h_3eb4f36ee2",
      //     },
      //     {
      //       title: "How to add HTML content to Popup message?",
      //       url: "https://help.marketpushapps.com/en/articles/8900488-how-to-use-image-hotspots#h_9e7bc726e1",
      //     },
      //     {
      //       title:
      //         "How can I Disable/Hide the popup message for a specific hotspot?",
      //       url: "https://help.marketpushapps.com/en/articles/8900488-how-to-use-image-hotspots#h_5e7e7e3036",
      //     },
      //   ],
      // },
    ],
  },

  tutorial_videos: {
    show: false,
    videos: [
      {
        title: "How to use Recently Viewed Products",
        thumbnail: "https://www.marketpushapps.com/images/image-hotspots.png",
        url: "https://www.youtube.com/watch?v=123456",
      },
    ],
  },

  intercom_widget: {
    show: true,
    appName: "Recently Viewed Products",

    negative_feedback_options: [
      {
        title: "Features do not work for me",
        message:
          "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
      },
      {
        title: "App setup is too complicated",
        message:
          "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
      },
      {
        title: "Subscriptions are too expensive",
        message:
          "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
      },
      {
        title: "App has issues",
        message:
          "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
      },
      {
        title: "Other",
        message: "🥲 Feedback: I have a problem with --> ",
      },
    ],
  },
};
