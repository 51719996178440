//

export default {
    app_info: {
        app_name: "tripadvisor_reviews",
        app_id: "0458be04-3b50-4957-802e-0273decfa126",
        app_title: "Tripadvisor Reviews",
        app_icon: "https://static.wixstatic.com/media/19e924_833168ce00774aaf871a0096ae09aa37~mv2.png/v1/fill/w_108,h_108,al_c,q_85,usm_0.66_1.00_0.01/19e924_833168ce00774aaf871a0096ae09aa37~mv2.webp",
    },

    main_links: {
        faq_url: "https://help.marketpushapps.com/en/articles/8696431-how-to-use-tripadvisor-reviews", 
        review_url: "https://www.wix.com/app-market/add-review/0458be04-3b50-4957-802e-0273decfa126",
    },

    tutorial_main: {
        show: false,
        title: "📘 Getting started guide",
        // TBD
        description: "Tripadvisor Reviews offers you different kinds of pricing plan templates. Choose from six templates with monthly and yearly options. Easy to use and keeps your pricing looking professional.",
        url: "https://help.marketpushapps.com/en/articles/8696431-how-to-use-tripadvisor-reviews",
    },

    tutorial_articles: {
        show: true,
        articles_collections: [{
            collection_name: "Getting Started",
            articles: [
                {
                    title: "1. How to use TripAdvisor Reviews?",
                    url: "https://help.marketpushapps.com/en/articles/8696431-how-to-use-tripadvisor-reviews",
                },
                {
                    title: "2. How to change widget position?",
                    url: "https://help.marketpushapps.com/en/articles/8828463-how-to-change-widget-position",
                },
                {
                    title: "3. How to add a link to your TripAdvisor page within the widget?",
                    url: "https://help.marketpushapps.com/en/articles/8828467-how-to-add-a-link-to-your-tripadvisor-page-within-the-widget",
                },
            ],
        },
        {
            collection_name: "Help Articles",
            articles: [
                {
                    title: "I don't have a TripAdvisor Page",
                    url: "https://help.marketpushapps.com/en/articles/8690773-i-don-t-have-a-tripadvisor-page",
                },

                {
                    title: "Is there a way to embed the widget so it doesn't scroll",
                    url: "https://help.marketpushapps.com/en/articles/8828461-is-there-a-way-to-embed-the-widget-so-it-doesn-t-scroll",
                },
            ],
        },
        ],
    },

    tutorial_videos: {
        show: true,
        videos: [{
            title: "How to use Tripadvisor Reviews",
            thumbnail: "https://www.marketpushapps.com/images/image-hotspots.png", // TBD
            url: "https://www.youtube.com/watch?v=bLAG-0a-L2c",
        },],
    },

    intercom_widget: {
        show: true,
        appName: "Tripadvisor Reviews",

        negative_feedback_options: [{
            title: "Features do not work for me",
            message: "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
        },
            {
                title: "App setup is too complicated",
                message: "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
            },
            {
                title: "Subscriptions are too expensive",
                message: "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
            },
            {
                title: "App has issues",
                message: "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
            },
            {
                title: "Other",
                message: "🥲 Feedback: I have a problem with --> ",
            },
        ],
    },
};