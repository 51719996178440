//

export default {
    app_info: {
        app_name: "facebook_reviews",
        app_id: "8d3fd1cb-1d5a-43ab-8109-766ae8d584ce",
        app_title: "Facebook Reviews",
        app_icon: "https://static.wixstatic.com/media/19e924_6f4ec046f9cd4bc8b151db9073189114~mv2.png/v1/fill/w_108,h_108,al_c,q_85,usm_0.66_1.00_0.01/19e924_6f4ec046f9cd4bc8b151db9073189114~mv2.webp",
    },

    main_links: {
        faq_url: "https://help.marketpushapps.com/en/articles/8702040-how-to-use-facebook-reviews-app", 
        review_url: "https://www.wix.com/app-market/add-review/8d3fd1cb-1d5a-43ab-8109-766ae8d584ce",
    },

    tutorial_main: {
        show: false,
        title: "📘 Getting started guide",
        // TBD
        description: "Facebook Reviews offers you different kinds of pricing plan templates. Choose from six templates with monthly and yearly options. Easy to use and keeps your pricing looking professional.",
        url: "https://help.marketpushapps.com/en/articles/8702040-how-to-use-facebook-reviews-app",
    },

    tutorial_articles: {
        show: true,
        articles_collections: [{
            collection_name: "Getting Started",
            articles: [
                {
                    title: "1. How to use Facebook Reviews App?",
                    url: "https://help.marketpushapps.com/en/articles/8702040-how-to-use-facebook-reviews-app",
                },
                {
                    title: "2. How to change widget position?",
                    url: "https://help.marketpushapps.com/en/articles/8713861-how-to-change-widget-position",
                },
                {
                    title: "3. How to add a link to your Facebook page within the widget?",
                    url: "https://help.marketpushapps.com/en/articles/8713887-how-to-add-a-link-to-your-facebook-page-within-the-widget",
                },
            ],
        },
        {
            collection_name: "Help Articles",
            articles: [
                {
                    title: "I don't have a Facebook Page",
                    url: "https://help.marketpushapps.com/en/articles/8713154-i-don-t-have-a-facebook-page",
                },

                {
                    title: "Is there a way to embed the widget so it doesn't scroll",
                    url: "https://help.marketpushapps.com/en/articles/8713279-is-there-a-way-to-embed-the-widget-so-it-doesn-t-scroll",
                },
                {
                    title: "My Facebook Page is not connecting",
                    url: "https://help.marketpushapps.com/en/articles/8713891-my-facebook-page-is-not-connecting",
                },
            ],
        },
        ],
    },

    tutorial_videos: {
        show: true,
        videos: [{
            title: "How to use Facebook Reviews",
            thumbnail: "https://www.marketpushapps.com/images/image-hotspots.png", // TBD
            url: "https://www.youtube.com/watch?v=fnroROvtRAA",
        },],
    },

    intercom_widget: {
        show: true,
        appName: "Facebook Reviews",

        negative_feedback_options: [{
            title: "Features do not work for me",
            message: "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
        },
            {
                title: "App setup is too complicated",
                message: "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
            },
            {
                title: "Subscriptions are too expensive",
                message: "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
            },
            {
                title: "App has issues",
                message: "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
            },
            {
                title: "Other",
                message: "🥲 Feedback: I have a problem with --> ",
            },
        ],
    },
};