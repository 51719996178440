export default {
  app_info: {
    app_name: "mkp-dev-staff-members",
    app_id: "27241c27-77a4-40bb-97d8-9cfa409318ec",
    app_title: "Staff Members",
    app_icon:
      "https://dev-reviews-mkp.nyc3.cdn.digitaloceanspaces.com/dev-mkp-staff-members/Logo/Logo.jpg",
  },

  main_links: {
    faq_url:
      "https://help.marketpushapps.com/en/articles/9944414-how-to-use-staff-members",
    review_url:
      "https://www.wix.com/app-market/add-review/27241c27-77a4-40bb-97d8-9cfa409318ec",
  },

  storage: {
    collection_name: "mkp-dev-staff-members",
  },

  tutorial_main: {
    show: true,
    title: "📘 Getting Started Guide",
    description:
      "The Staff Members Widget allows you to showcase your team with customizable layouts and detailed staff profiles. Easily display employee roles, bios, and contact information in a clean, professional design. Follow the guide to set up the widget and highlight your team on your site.",
    url: "https://help.marketpushapps.com/en/articles/9944414-how-to-use-staff-members",
  },

  tutorial_articles: {
    show: false,
    articles_collections: [
      {
        collection_name: "Staff Members",
        articles: [
          {
            title: "1. How to use Default App Collection?",
            url: "https://help.marketpushapps.com/en/articles/9414682-how-to-use-default-app-collection.",
          },
          {
            title: "2. How to use Custom Collection?",
            url: "https://help.marketpushapps.com/en/articles/9820351-how-to-use-custom-collection.",
          },
          {
            title: "3. How to use Search & Filter Gallery?",
            url: "https://help.marketpushapps.com/en/articles/9820720-how-to-use-search-filter-gallery.",
          },
        ],
      },
      // {
      //   collection_name: "Help Articles",
      //   articles: [
      //     {
      //       title: "How to add colors without a color picker?",
      //       url: "https://help.marketpushapps.com/en/articles/8900488-how-to-use-image-hotspots#h_e750f2ba12",
      //     },
      //     {
      //       title: "How to upload my own image?",
      //       url: "https://help.marketpushapps.com/en/articles/8900488-how-to-use-image-hotspots#h_3eb4f36ee2",
      //     },
      //     {
      //       title: "How to add HTML content to Popup message?",
      //       url: "https://help.marketpushapps.com/en/articles/8900488-how-to-use-image-hotspots#h_9e7bc726e1",
      //     },
      //     {
      //       title:
      //         "How can I Disable/Hide the popup message for a specific hotspot?",
      //       url: "https://help.marketpushapps.com/en/articles/8900488-how-to-use-image-hotspots#h_5e7e7e3036",
      //     },
      //   ],
      // },
    ],
  },

  tutorial_videos: {
    show: false,
    videos: [
      {
        title: "How to use image hotspots",
        thumbnail: "https://www.marketpushapps.com/images/image-hotspots.png",
        url: "https://www.youtube.com/watch?v=123456",
      },
    ],
  },

  intercom_widget: {
    show: true,
    appName: "Staff Members",

    negative_feedback_options: [
      {
        title: "Features do not work for me",
        message:
          "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
      },
      {
        title: "App setup is too complicated",
        message:
          "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
      },
      {
        title: "Subscriptions are too expensive",
        message:
          "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
      },
      {
        title: "App has issues",
        message:
          "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
      },
      {
        title: "Other",
        message: "🥲 Feedback: I have a problem with --> ",
      },
    ],
  },
};
