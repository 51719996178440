export default {
    app_info: {
        app_name: "typeform_embed",
        app_id: "15d1b11b-e63f-4306-81be-91e41afc408b",
        app_title: "Typeform Embed",
        app_icon: "https://static.wixstatic.com/media/0904c3_77085978dcb642c0a89c3469f7c19b6b~mv2.png",
    },

    main_links: {
        faq_url: "",
        review_url: "https://www.wix.com/app-market/add-review/15d1b11b-e63f-4306-81be-91e41afc408b",
    },

    tutorial_main: {
        show: false,
        title: "📘 Getting started guide",
        description: "Typeform Embed lets you quickly add customizable Typeform forms to your site. This app makes it easy to create professional forms that fit seamlessly into your design. Effortlessly engage visitors with interactive, branded forms right on your website.",
        url: "",
    },

    tutorial_articles: {
        show: false,
        articles_collections: [{
                collection_name: "Getting Started",
                // TBD
                articles: [
                    {
                        title: "1. How to add the app & change the template?",
                        url: "https://help.marketpushapps.com/en/articles/10019618-how-to-use-logo-showcase#h_a863a25f52",
                    },
                    {
                        title: "2. What are the available templates?",
                        url: "https://help.marketpushapps.com/en/articles/10019618-how-to-use-logo-showcase#h_6cd334d305",
                    },
                    {
                        title: "3. Can my customers add a review?",
                        url: "https://help.marketpushapps.com/en/articles/10019618-how-to-use-logo-showcase#h_790db58c2a",
                    },
                    {
                        title: "4. How to modify the content?",
                        url: "https://help.marketpushapps.com/en/articles/10019618-how-to-use-logo-showcase#h_74a006b1e6",
                    },
                    {
                        title: "4. How to add/or remove items",
                        url: "https://help.marketpushapps.com/en/articles/10019618-how-to-use-logo-showcase#h_cd9e7a2422",
                    },
                ],
            },
        ],
    },

    tutorial_videos: {
        show: false, // TBD
        videos: [{
            title: "How to use Typeform Embed",
            thumbnail: "https://www.marketpushapps.com/images/image-hotspots.png", // TBD
            url: "https://www.youtube.com/watch?v=123456", // TBD
        }, ],
    },

    intercom_widget: {
        show: true,
        appName: "Typeform Embed",

        negative_feedback_options: [{
                title: "Features do not work for me",
                message: "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
            },
            {
                title: "App setup is too complicated",
                message: "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
            },
            {
                title: "Subscriptions are too expensive",
                message: "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
            },
            {
                title: "App has issues",
                message: "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
            },
            {
                title: "Other",
                message: "🥲 Feedback: I have a problem with --> ",
            },
        ],
    },
};